// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable no-case-declarations */
import { TAR_PROCESS_ENUM, PAYMENT_TYPES } from '@ekhodealer/ekho-common';

export const MAX_IDENTITY_VERIFICATION_ATTEMPTS = 3;

/* ENVIRONMENT CONSTS */

export const COLORS = {
	PRIMARY: {
		name: 'primary',
		code: process.env.REACT_APP_PRIMARY_COLOR,
	},
	SECONDARY: {
		name: 'secondary',
		code: process.env.REACT_APP_SECONDARY_COLOR,
	},
	SUCCESS: {
		name: 'success',
		code: process.env.REACT_APP_SUCCESS_COLOR,
	},
	INFO: {
		name: 'info',
		code: process.env.REACT_APP_INFO_COLOR,
	},
	WARNING: {
		name: 'warning',
		code: process.env.REACT_APP_WARNING_COLOR,
	},
	DANGER: {
		name: 'danger',
		code: process.env.REACT_APP_DANGER_COLOR,
	},
	DARK: {
		name: 'dark',
		code: process.env.REACT_APP_DARK_COLOR,
	},
	LIGHT: {
		name: 'light',
		code: process.env.REACT_APP_LIGHT_COLOR,
	},
	// Color Hex codes
	BACKGROUND_GRAY: {
		name: 'backgroundGray',
		code: '#f8f9fa',
	},
	BORDER_GRAY: {
		name: 'borderGray',
		code: 'var(--bs-card-border-color)',
	},
};

export const ENV = {
	DEVELOPMENT: 'development',
	STAGING: 'staging',
	PRODUCTION: 'production',
};

/* END ENVIRONMENT CONSTS */

/* AUTH CONSTS */

export const ACCOUNT_TYPE = {
	BUYER: 'buyer',
	ADMIN: 'Oemadmin',
};

export const VALID_LOGIN_TYPE = {
	ADMIN: 'admin',
	EMAIL_VERIFICATION: 'emailVerification',
	BUYER: 'buyer',
};

export const FEE_ESTIMATE_FIDELITIES = {
	INITIAL: 'initial',
	FINAL: 'final',
	API_ESTIMATE: 'apiEstimate',
};

/* AUTH CONSTS */

export const GOOGLE_MAPS_LIBRARIES = ['places', 'geometry'];

/* VEHICLE CONSTS */

export const VEHICLE_STAGE = {
	SIGN_OFF: 'signOff',
	PENDING_PAYMENT_GUARANTEE: 'pendingPaymentGuarantee',
	PENDING_VERIFICATION: 'pendingVerification',
	PRODUCTION: 'production',
	COMPLETE: 'complete',
};

export const VEHICLE_STAGE_TO_STRING_MAP = {
	[VEHICLE_STAGE.SIGN_OFF]: 'Sign off',
	[VEHICLE_STAGE.PENDING_PAYMENT_GUARANTEE]: 'Pending payment guarantee',
	[VEHICLE_STAGE.PENDING_VERIFICATION]: 'Pending identity verification',
	[VEHICLE_STAGE.PRODUCTION]: 'Production',
	[VEHICLE_STAGE.COMPLETE]: 'Complete',
};

export const VehicleFulfillmentStatusOptions = {
	UNFULFILLED: 'UNFULFILLED',
	ON_HOLD: 'ON_HOLD',
	CANCELED: 'CANCELED',
	FULFILLMENT_REQUESTED: 'FULFILLMENT_REQUESTED',
	FULFILLED: 'FULFILLED',
	SHIPPED: 'SHIPPED',
	READY_FOR_PICK_UP: 'READY_FOR_PICK_UP',
};

export const OEMS_PROCESSING_REG_31 = [
	'QZzao3tGSmDzt94SfAWG', // Maeving
	'Ju81l5hQ84wFV9M4qz5b', // Stark (not actually processing but we don't think it is required for them)
];

export const VERIFICATION_STATUS_ENUM = {
	NOT_STARTED: 'not_started',
	VERFIFIED: 'verified',
	REQUIRES_INPUT: 'requires_input',
	PROCESSING: 'processing',
	CREATED: 'created',
	CANCELED: 'canceled',
	FAILURE: 'failure',
};

export const TYPE_OF_ORDER_ENUM = {
	PREORDER: 'preorder',
	BUILD_TO_ORDER: 'buildToOrder',
	INVENTORY_ORDER: 'inventoryOrder',
};

export const COMMENCEMENT_MODAL_SECTIONS = {
	INFO_COLLECT: 'infoCollect',
	SIGNOFF: 'signOff',
};

// Position enum for relocation and modal queue objects
export const QUEUE_POSITION_ENUM = {
	FRONT: 'FRONT',
	BACK: 'BACK',
};

// Enum of all configured relocation flows
export const RELOCATION_FLOWS = {
	SELECT_DELIVERY_PREF: 'SELECT_DELIVERY_PREF',
	FINANCING_RESULT: 'FINANCING_RESULT',
	FAILED_ACH_PAYMENT: 'FAILED_ACH_PAYMENT',
	SIGN_RIC: 'SIGN_RIC',
	FINANCING_STIPULATIONS: 'FINANCING_STIPULATIONS',
	// RETRY_INSURANCE_VALIDATION: 'RETRY_INSURANCE_VALIDATION',
	// Add other relocation flows here...
	// Note: must also add to backend
};

export const DELIVERY_CHOICES = {
	SHIP: 'SHIP',
	PICK_UP: 'PICK_UP',
};

export const PICK_UP_LOCATION_FILTERS = {
	ALL: {
		id: 'ALL',
		name: 'All',
	},
	OEM_AND_DEALERS: {
		id: 'OEM_AND_DEALERS',
		name: 'OEM and dealers',
	},
};

export const EKHO_PICK_UP_LOCATION_ID = 'ekho-dealer-pickup';

/* END VEHICLE CONSTS */

/* PAYMENT CONSTS */

export const LOAN_OFFER_WIDTH_THRESHOLD = 430;

// The payment intent types that originate from checkout
export const CHECKOUT_PAYMENT_TYPES = [
	PAYMENT_TYPES.CHECKOUT_FULL_PAYMENT,
	PAYMENT_TYPES.STANDARD_RESERVATION,
	PAYMENT_TYPES.LOAN_DOWNPAYMENT,
];

export const BUYER_PORTAL_PAYMENT_TYPES = [
	PAYMENT_TYPES.EARLY_REMAINING_BALANCE,
	PAYMENT_TYPES.LATE_REMAINING_BALANCE,
	PAYMENT_TYPES.LOAN_ADDITIONAL_DOWN,
];

export const DISCOUNT_TYPES = {
	PERCENTAGE_DISCOUNT: 'PERCENTAGE_DISCOUNT',
	FIXED_AMOUNT_DISCOUNT: 'FIXED_AMOUNT_DISCOUNT',
	SHIPPING_DISCOUNT: 'SHIPPING_DISCOUNT',
};

// The 3 different umbrella descriptions of all payment intent types
export const PAYMENT_DESCRIPTION_TYPES = {
	RESERVATION_PAYMENT: {
		description: 'Reservation Payment',
		paymentTypes: [PAYMENT_TYPES.STANDARD_RESERVATION, PAYMENT_TYPES.LOAN_DOWNPAYMENT],
	},
	REMAINING_BALANCE_PAYMENT: {
		description: 'Remaining Balance Payment',
		paymentTypes: [
			PAYMENT_TYPES.EARLY_REMAINING_BALANCE,
			PAYMENT_TYPES.LATE_REMAINING_BALANCE,
			PAYMENT_TYPES.CHECKOUT_FULL_PAYMENT,
		],
	},
	LOAN_ADDITIONAL_DOWN: {
		description: 'Loan Additional Down Payment',
		paymentTypes: [PAYMENT_TYPES.LOAN_ADDITIONAL_DOWN],
	},
};

export const TEST_DEPOSIT_AMOUNT = 0.01;

// Stripe payment status enum
export const PAYMENT_STATUS_ENUM = {
	PROCESSING: 'processing',
	SUCCEEDED: 'succeeded',
	REQUIRES_PAYMENT_METHOD: 'requires_payment_method',
	REQUIRES_ACTION: 'requires_action',
	CANCELED: 'canceled',
	REQUIRES_CONFIRMATION: 'requires_confirmation',
	REQUIRES_CAPTURE: 'requires_capture',
};

// Duplicate of backend enum
export const VehiclePaymentStatusOptions = {
	RESERVATION_PAID: 'RESERVATION_PAID',
	FINANCING_GUARANTEED: 'FINANCING_GUARANTEED',
	FULLY_PAID: 'FULLY_PAID',
	DOWNPAYMENT_PAID: 'DOWNPAYMENT_PAID',
};

// export const AVERAGE_COMBINED_SALES_TAX_PERCENT = 0.092;

/* END PAYMENT CONSTS */

/* TITLING AND REG CONSTS */
export const NOTARY_FORM_TYPE = {
	NONE: 'NONE',
	NA: 'NA',
	ENOTARY: 'ENOTARY',
	PHYSICAL: 'PHYSICAL',
};

export const SIG_WORKFLOW_TYPE = {
	ESIGN: 'ESIGN',
	WET: 'WET',
};

export const SIGNATURE_PAGE_NAMES = {
	documentsSignatures: 'documentsSignatures',
	nativeFinancingSignatures: 'nativeFinancingSignatures',
	tarSignatures: 'tarSignatures',
};

export const NOTARY_WORKFLOW_TYPE = {
	ENOTARY: 'ENOTARY',
	PHYSICAL: 'PHYSICAL',
	NONE: 'NONE',
};

/* END TITLING AND REG CONSTS */

export const DOCUMENT_STEPS_ENUM = {
	FINALIZED_BUYER_PACKET: 'FINALIZED_BUYER_PACKET',
	BUYER_SENT_DOCUMENTS: 'BUYER_SENT_DOCUMENTS',
	OEM_SENT_DOCUMENTS: 'OEM_SENT_DOCUMENTS',
	LIENHOLDER_SENT_DOCUMENTS: 'LIENHOLDER_SENT_DOCUMENTS',
	SELLER_RECEIVED_ALL_DOCUMENTS: 'SELLER_RECEIVED_ALL_DOCUMENTS',
	SELLER_RECEIVED_BUYER_DOCUMENTS: 'SELLER_RECEIVED_BUYER_DOCUMENTS',
	SELLER_RECEIVED_OEM_DOCUMENTS: 'SELLER_RECEIVED_OEM_DOCUMENTS',
	SELLER_RECEIVED_LIENHOLDER_DOCUMENTS: 'SELLER_RECEIVED_LIENHOLDER_DOCUMENTS',
	SELLER_SENT_DOCUMENTS: 'SELLER_SENT_DOCUMENTS',
};

/* SECTION CONSTS */

export const sectionKeys = {
	signOff: 'signOff',
	financing: 'financing',
	registrationAndTitling: 'registrationAndTitling',
	deliveryDetails: 'deliveryDetails',
	insurance: 'insurance',
	payment: 'payment',
	documentsAndSignatures: 'documentsAndSignatures',
};

export const ACTIVE_SECTION_KEY = 'activeSection';

export const COMPLETED_PAGE_SUFFIX = 'CompletedPage';

export const ALL_SECTION_ORDER = [
	'signOff',
	'deliveryDetails',
	'financing',
	'registrationAndTitling',
	'insurance',
	'payment',
	'documentsAndSignatures',
];

export function getSectionDataMap(tarProcess, key) {
	switch (key) {
		case 'signOff':
			return {
				numSteps: 2,
				highLevelInfo: {
					title: 'Sign Off',
					timeEst: '1 minute',
					description: 'Sign off on your vehicle.',
				},
			};
		case 'financing':
			return {
				numSteps: 5,
				highLevelInfo: {
					title: 'Financing',
					timeEst: '2 minutes',
					description: 'Finalize payment of your vehicle.',
				},
			};
		case 'deliveryDetails':
			return {
				numSteps: 3,
				highLevelInfo: {
					title: 'Delivery Details',
					timeEst: '2 minutes',
					description: 'Finalize delivery details of your vehicle.',
				},
			};
		case 'insurance':
			return {
				numSteps: 5,
				highLevelInfo: {
					title: 'Protection',
					timeEst: '3 minutes',
					description: "Choose how you'd like to protect your vehicle.",
				},
			};
		case 'registrationAndTitling':
			let title = 'Registration';
			let description = 'Get your vehicle registered quickly and easily.';
			if (
				tarProcess === TAR_PROCESS_ENUM.ASSISTED ||
				tarProcess === TAR_PROCESS_ENUM.ASSISTED_TITLING ||
				tarProcess === TAR_PROCESS_ENUM.NONE
			) {
				title = 'Confirm your details';
				description = `Confirm your personal information.`;
			}
			if (tarProcess === TAR_PROCESS_ENUM.AUTOMATED_TITLING) {
				title = 'Titling';
				description = 'Get your vehicle titled quickly and easily.';
			}
			return {
				numSteps: 7,
				highLevelInfo: {
					title,
					timeEst: '1 minute',
					description,
				},
			};
		case 'documentsAndSignatures':
			return {
				numSteps: 3,
				highLevelInfo: {
					title: 'Paperwork',
					timeEst: '2 minutes',
					description: 'Sign and submit your paperwork.',
				},
			};
		case 'payment':
			return {
				numSteps: 3,
				highLevelInfo: {
					title: 'Payment',
					timeEst: '2 minutes',
					description: 'Finalize payment of your vehicle.',
				},
			};
		default:
			return null;
	}
}

const regDetails = {
	registrantType: {
		title: 'Registrant type',
		rank: 1,
	},
	plateCategory: {
		title: 'Plate category',
		rank: 2,
	},
	useType: {
		title: 'Vehicle use type',
		rank: 3,
	},
	ownerType: {
		title: 'Registered owner type',
		rank: 4,
	},
	sex: {
		title: 'Sex',
		rank: 5,
	},
	phoneNumber: {
		title: 'Phone number',
		rank: 6,
	},
	email: {
		title: 'Email address',
		rank: 7,
	},
	mailAddressSameAsReg: {
		title: 'Mailing address same as registration address',
		rank: 8,
	},
	isOwnerMilitary: {
		title: 'Owner is military',
		rank: 9,
	},
	registrationAddress: {
		addressLineOne: {
			title: 'Registration address line 1',
			rank: 10,
		},
		addressLineTwo: {
			title: 'Registration address line 2',
			rank: 11,
		},
		city: {
			title: 'Registration address city',
			rank: 12,
		},
		county: {
			title: 'Registration address county',
			rank: 13,
		},
		state: {
			title: 'Registration address state',
			rank: 14,
		},
		zip: {
			title: 'Registration address zip',
			rank: 15,
		},
	},
};

export const REGDETAILS_NUM_ENTRIES = 15;
export const REGDETAILS_NUM_ENTRIES_WITH_MAIL = 21;

export const fieldMaps = {
	businessDetailsFieldMap: {
		businessName: {
			title: 'Business name',
			rank: 10,
		},
		fein: { title: 'FEID/FEIN', rank: 11 },
		businessPhoneNumber: {
			title: 'Business phone number',
			rank: 12,
		},
	},
	nativeFinancingChoiceFieldMap: {
		name: {
			title: 'Loan offer name',
			rank: 1,
		},
		loanTerm: {
			title: 'Loan term (in months)',
			rank: 2,
		},
		apr: {
			title: 'APR of your loan (in percent)',
			rank: 3,
		},
		// Removing these for now since we don't want buyers to be able to see this bc they will change
		// monthlyPayment: {
		// 	title: 'Monthly payment',
		// 	rank: 4,
		// },
		// loanAmount: {
		// 	title: 'Amount financed',
		// 	rank: 5,
		// },
		// totalPayment: {
		// 	title: "Total amount you'll pay over time",
		// 	rank: 6,
		// },
		// downPayment: {
		// 	title: 'Down payment (i.e. the amount you pay up front)',
		// 	rank: 7,
		// },
	},
	// otherLenderInfoFieldMap: {
	// 	name: {
	// 		title: 'Name of lender',
	// 		rank: 1,
	// 	},
	// 	apr: {
	// 		title: 'APR of your loan (in percent)',
	// 		rank: 2,
	// 	},
	// 	term: {
	// 		title: 'Loan term (number of months)',
	// 		rank: 3,
	// 	},
	// },
	registrantDetailsFieldMapNoMail: regDetails,
	registrantDetailsFieldMapWithMail: {
		...regDetails,
		mailingAddress: {
			addressLineOne: {
				title: 'Mailing address line 1',
				rank: 16,
			},
			addressLineTwo: {
				title: 'Mailing address line 2',
				rank: 17,
			},
			city: {
				title: 'Mailing address city',
				rank: 18,
			},
			county: {
				title: 'Mailing address county',
				rank: 19,
			},
			state: {
				title: 'Mailing address state',
				rank: 20,
			},
			zip: {
				title: 'Mailing address zip',
				rank: 21,
			},
		},
	},
	applicantDetailsFieldMap: {
		// firstName: {
		// 	title: 'First name',
		// 	rank: 1,
		// },
		// lastName: {
		// 	title: 'Last name',
		// 	rank: 2,
		// },
		// dob: {
		// 	title: 'Date of birth',
		// 	rank: 3,
		// },
		email: {
			title: 'Email',
			rank: 4,
		},
		phoneNumber: {
			title: 'Phone number',
			rank: 5,
		},
		ssn: {
			title: 'SSN',
			rank: 6,
		},
		addressLineOne: {
			title: 'Address line 1',
			rank: 7,
		},
		addressLineTwo: {
			title: 'Address line 2',
			rank: 8,
		},
		city: {
			title: 'Address city',
			rank: 9,
		},
		county: {
			title: 'Address county',
			rank: 10,
		},
		state: {
			title: 'Address state',
			rank: 11,
		},
		zip: {
			title: 'Address zip',
			rank: 12,
		},
		maritalStatus: {
			title: 'Marital status',
			rank: 13,
		},
		housingOwnership: {
			title: 'Housing ownership',
			rank: 14,
		},
		homeMoveInDate: {
			title: 'Home move-in date',
			rank: 15,
		},
		monthlyAmt: {
			title: 'Monthly payment',
			rank: 16,
		},
	},
	applicantEmploymentFieldMap: {
		employmentStatus: {
			title: 'Employment status',
			rank: 1,
		},
		occupation: {
			title: 'Occupation',
			rank: 2,
		},
		monthlyGrossIncome: {
			title: 'Monthly gross income',
			rank: 3,
		},
		jobStartDate: {
			title: 'Job start date',
			rank: 4,
		},
		employerName: {
			title: 'Employer name',
			rank: 5,
		},
		addressLineOne: {
			title: 'Address line 1',
			rank: 6,
		},
		addressLineTwo: {
			title: 'Address line 2',
			rank: 7,
		},
		city: {
			title: 'Address city',
			rank: 8,
		},
		county: {
			title: 'Address county',
			rank: 9,
		},
		state: {
			title: 'Address state',
			rank: 10,
		},
		zip: {
			title: 'Address zip',
			rank: 11,
		},
		otherIncomes: {
			title: 'Total in other income (monthly)',
			rank: 12,
		},
	},
	// coApplicantDetailsFieldMap: {
	// 	firstName: 'First name',
	// 	lastName: 'Last name',
	// 	dob: 'Date of birth',
	// 	ssn: 'SSN',
	// 	phoneNumber: 'Phone number',
	// 	addressLineOne: 'Address line 1',
	// 	addressLineTwo: 'Address line 2',
	// 	city: 'Address city',
	// 	state: 'Address state',
	// 	zip: 'Address zip',
	// 	maritalStatus: 'Marital status',
	// 	hasCoApplicant: 'Has co-applicant',
	// },
	// coApplicantEmploymentFieldMap: {
	// 	employerName: 'Employer name',
	// 	addressLineOne: 'Address line 1',
	// 	addressLineTwo: 'Address line 2',
	// 	city: 'Address city',
	// 	state: 'Address state',
	// 	zip: 'Address zip',
	// 	jobStartDate: 'Job start date',
	// 	occupation: 'Occupation',
	// 	monthylGrossIncome: 'Monthly gross income',
	// },
	pickUpDetailsFieldMap: {
		pickUpLocationName: {
			title: 'Pick up location name',
			rank: 1,
		},
		addressLineOne: {
			title: 'Pick up location street',
			rank: 2,
		},
		addressLineTwo: {
			title: 'Pick up location apt/suite',
			rank: 3,
		},
		city: {
			title: 'Pick up location city',
			rank: 4,
		},
		state: {
			title: 'Pick up location state',
			rank: 5,
		},
		zip: {
			title: 'Pick up location zip',
			rank: 6,
		},
		// pickUpDate: {
		// 	title: 'Pick up date',
		// 	rank: 7,
		// },
		// pickUpTime: {
		// 	title: 'Pick up time',
		// 	rank: 8,
		// },
	},
	deliveryDetailsFieldMap: {
		addressLineOne: {
			title: 'Address line 1',
			rank: 1,
		},
		addressLineTwo: {
			title: 'Address line 2',
			rank: 2,
		},
		city: {
			title: 'Address city',
			rank: 3,
		},
		state: {
			title: 'Address state',
			rank: 4,
		},
		zip: {
			title: 'Address zip',
			rank: 5,
		},
		deliveryInstructions: {
			title: 'Delivery instructions',
			rank: 6,
		},
		poc: {
			title: 'Delivery point of contact',
			rank: 7,
		},
		pocName: {
			title: 'Point of contact name',
			rank: 8,
		},
		pocNumber: {
			title: 'Point of contact number',
			rank: 9,
		},
	},
	basicInsuranceDetailsFieldMap: {
		// Create a map from the field name to the title
		name: {
			title: 'Name of insurance policy',
			rank: 1,
		},
		carrierName: {
			title: 'Insurance carrier name',
			rank: 2,
		},
		policyId: {
			title: 'Policy ID',
			rank: 3,
		},
		policyType: {
			title: 'Policy type',
			rank: 4,
		},
		effectiveDate: {
			title: 'Effective date',
			rank: 5,
		},
		expiryDate: {
			title: 'Expiry date',
			rank: 6,
		},
		carrierPolicyNumber: {
			title: 'Carrier policy number',
			rank: 7,
		},
		issuingState: {
			title: 'Issuing state',
			rank: 8,
		},
		agentBrokerName: {
			title: 'Insurance agent/broker name',
			rank: 9,
		},
	},
};

// Credit application lender decision types
export const creditAppDecisionTypes = {
	accepted: 'accepted',
	rejected: 'rejected',
	needMoreInfo: 'needMoreInfo',
	counterOffer: 'counterOffer',
};

// The different types of decisions a buyer can make on a finance offer from the lender. This will grow with time.
export const financeOfferBuyerDecisionTypes = {
	accepted: 'accepted',
	declined: 'declined',
};

export const financeActionItemOptions = {
	WAIT_FOR_SUBMISSION_WINDOW: 'WAIT_FOR_SUBMISSION_WINDOW',
	SUBMIT_CREDIT_APP_AND_REPORT_DECISION: 'SUBMIT_CREDIT_APP_AND_REPORT_DECISION',
	GATHER_ADDITIONAL_INFO: 'GATHER_ADDITIONAL_INFO',
	PERFORM_PREAUDIT: 'PERFORM_PREAUDIT',
	SIGN_DOCS: 'SIGN_DOCS',
	SUBMIT_FUNDING_PACKAGE: 'SUBMIT_FUNDING_PACKAGE',
	BLOCKED: 'BLOCKED',
	COMPLETE: 'COMPLETE',
};
// Vehicle stages
export const vehicleStageToNum = {
	signOff: 1,
	provisionParts: 2,
	production: 3,
	waitingToShip: 4,
	shipped: 5,
};

// Map from env to firebase database project id
export const ENV_TO_PROJECT_MAP = {
	[ENV.DEVELOPMENT]: 'airdealer-67669',
	[ENV.STAGING]: 'ed-staging',
};

export const MAX_INSURANCE_VERIFICATION_ATTEMPTS = 3;

export const rejectedCreditDecision = {
	decision: 'rejected',
	apr: 0.05,
	downPayment: 500,
	loanAmount: 30200,
	loanTerm: 24,
	monthlyPayment: 400,
	buyerOfferDecision: null,
};

export const needMoreInfoCreditDecision = {
	decision: 'needMoreInfo',
	apr: 0.05,
	downPayment: 500,
	loanAmount: 30200,
	loanTerm: 24,
	monthlyPayment: 400,
	buyerOfferDecision: null,
};

export const acceptedCreditDecision = {
	additionalDuesId: 'test-dues-id-1',
	decision: 'accepted',
	apr: 0.05,
	downPayment: 500,
	loanAmount: 30200,
	loanTerm: 24,
	monthlyPayment: 400,
	buyerOfferDecision: null,
};

// The target down payment we set for the Pay Sooner option
export const paySoonerDownPaymentPerc = 0.05;
// The target down payment we set for the Pay Less Now option
export const payLessNowDownPaymentPerc = 0.2;

export const STATE_ABBR_TO_FULL = {
	AL: 'Alabama',
	AK: 'Alaska',
	AZ: 'Arizona',
	AR: 'Arkansas',
	CA: 'California',
	CO: 'Colorado',
	CT: 'Connecticut',
	DE: 'Delaware',
	FL: 'Florida',
	GA: 'Georgia',
	ID: 'Idaho',
	IL: 'Illinois',
	IN: 'Indiana',
	IA: 'Iowa',
	KS: 'Kansas',
	KY: 'Kentucky',
	LA: 'Louisiana',
	ME: 'Maine',
	MD: 'Maryland',
	MA: 'Massachusetts',
	MI: 'Michigan',
	MN: 'Minnesota',
	MS: 'Mississippi',
	MO: 'Missouri',
	MT: 'Montana',
	NE: 'Nebraska',
	NV: 'Nevada',
	NH: 'New Hampshire',
	NJ: 'New Jersey',
	NM: 'New Mexico',
	NY: 'New York',
	NC: 'North Carolina',
	ND: 'North Dakota',
	OH: 'Ohio',
	OK: 'Oklahoma',
	OR: 'Oregon',
	PA: 'Pennsylvania',
	RI: 'Rhode Island',
	SC: 'South Carolina',
	SD: 'South Dakota',
	TN: 'Tennessee',
	TX: 'Texas',
	UT: 'Utah',
	VT: 'Vermont',
	VA: 'Virginia',
	WA: 'Washington',
	WV: 'West Virginia',
	WI: 'Wisconsin',
	WY: 'Wyoming',
};

export const SECTION_END = 'SECTION_END';

export const DEMO_DOCUMENT_SIGNING_LINK_MAP = {
	tarSignatures: 'w2L5muZxSnUMjBmwj4BX6K',
	nativeFinancingSignatures: 'vxU6TkvJskpBLDXbbx2BoY',
};
